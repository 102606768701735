import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"main-content"},[_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,[_vm._v(" "+_vm._s(_vm.type.name)+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"dense":"","small":"","elevation":"0"},on:{"click":function($event){_vm.dialog.create = true}}},[_vm._v(" "+_vm._s(_vm.$t('create-new'))+" ")]),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)],1),_c(VContainer)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }