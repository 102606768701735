
export default {
  name: 'CompetitionPage',
  props: {
    type: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      dialog: {
        create: false
      },
      data: ''
    }
  }
}
